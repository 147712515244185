import { Component, OnInit } from '@angular/core';
import { forkJoin, concat } from 'rxjs';
import { LogInventarioService } from 'src/app/services/logInventario/log-inventario.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import { VentasService } from 'src/app/services/ventas/ventas.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-surtido-productos',
  templateUrl: './surtido-productos.component.html',
  styleUrls: ['./surtido-productos.component.css']
})
export class SurtidoProductosComponent implements OnInit {

  // Variables
  pedidoActual: any;
  pedidoActualSurtido = false;

  // Data
  pedidosPorSurtir: any[];

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDePedidosPorSurtir: number;

  constructor(
    private _ventasService: VentasService,
    private _productoService: ProductoService,
    private _logInventarioService: LogInventarioService
  ) { }

  ngOnInit(): void {
    this.obtenerPedidosPorSurtir( 1 );
  }

  obtenerPedidosPorSurtir( pagina: number, limit = 10 ) {
    this._ventasService.obtenerVentasPorSurtir( pagina, limit ).subscribe(
      (resp: any) => {
        this.pedidosPorSurtir = resp.pedidosPorSurtir;
        this.totalDePedidosPorSurtir = resp.totalPedidosPorSurtir;
        this.totalDePedidosPorSurtir = resp.totalPedidosPorSurtir;
        this.totalPages = Math.ceil( this.totalDePedidosPorSurtir / 10 );
        this.paginaActual = pagina;
      },
      (error) => {
        swal(
          'Error al obtener pedidos por surtir',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  seleccionarPedidoASurtir( pedido ) {
    this.pedidoActual = pedido;
    this.pedidoActualSurtido = false;
    this.verificarTodosLosProductosSurtidos();

  }

  paginaAnterior() {
    if (this.paginaActual === 1) {
      return;
    }

    this.paginaActual -= 1;
    this.obtenerPedidosPorSurtir( this.paginaActual, 10 );
  }

  paginaSiguiente() {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    this.paginaActual += 1;
    this.obtenerPedidosPorSurtir( this.paginaActual, 10 );
  }

  registrarRecoleccionDeProducto( evento, index: number ) {
    const escaneo = evento.target.value;

    if ( this.pedidoActual.carrito[index].codigo != escaneo ) {
      swal(
        'El código escaneado no coincide',
        `El código escaneado ${ escaneo } es distinto al solicitado ${ this.pedidoActual.carrito[index].codigo } por el cliente`,
        'warning'
      );

      evento.target.value = '';
      return;
    }

    this.pedidoActual.carrito[index].surtido = true;

    this.verificarTodosLosProductosSurtidos();
  }

  verificarTodosLosProductosSurtidos() {

    if ( this.pedidoActual.carrito.some( ( producto ) => !producto.surtido ) ) {
      this.pedidoActualSurtido = false;
      return;
    }

    this.pedidoActualSurtido = true;

  }

  surtirPedido() {
    if ( !this.pedidoActualSurtido ) {
      return;
    }

    this.pedidoActual.pedidoSurtido = true;
    this.pedidoActual.fehcaSurtido = new Date();

    this._ventasService.actualizarVenta( this.pedidoActual._id, this.pedidoActual ).subscribe(
      (resp: any) => {

        let tasks = this.pedidoActual.carrito.map((producto, index) => {
            return this.descontarPiezasDeInventario(producto._id, producto.cantidad);
          });

          tasks = tasks.flat(1);

          const concatenatedTasks = concat(...tasks);

          concatenatedTasks
            .subscribe( (resp: any) => {
          },
          (error) => {
            swal(
              'Ocurrio un error al actualizar el inventario',
              error.error.mensaje + ' | ' + error.error.errors.message,
              'error'
              );
          }).add( () => {
            this.pedidoActual = null;
            this.pedidoActualSurtido = false;
            this.obtenerPedidosPorSurtir( this.paginaActual );

            swal(
              'Pedido surtido exitosamente',
              'El pedido se surtió exitosamente',
              'success'
            );
          });

    },
    (error) => {
      swal(
        'Error al actualizar pedido como surtido',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  descontarPiezasDeInventario( productId: string, cantidad: number ) {

    const log = {
      tipoDeMoviento: 'Baja de Inventario',
      producto: productId,
      cantidad: -cantidad,
      venta: this.pedidoActual._id,
      descripcion: 'Venta de producto',
    };

    return [
      this._productoService.salidaDeInventario( productId, cantidad ),
      this._logInventarioService.crearLogDeInventario(log)
    ];
  }

}
