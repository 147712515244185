import { Component, OnInit } from '@angular/core';
import { FacturasService } from 'src/app/services/facturas.service';
import { VentasService } from 'src/app/services/ventas/ventas.service';
import { formatCurrency } from '@angular/common';
import swal from 'sweetalert';
import {Invoice2023Service} from '../../services/invoice2023.service';
import {ClienteService} from '../../services/clientes/cliente.service';

@Component({
  selector: 'app-facturar-ventas',
  templateUrl: './facturar-ventas.component.html',
  styleUrls: ['./facturar-ventas.component.css']
})
export class FacturarVentasComponent implements OnInit {

  // Data
  ventasPorFacturar: any[];
  formasDePago: any[];
  metodosDePagoFacturacion: any[];
  usosCFDI: any[];

  // Variables
  paginaActual: number;
  totalPaginas: number;
  limit: number;


  constructor(
    private _ventasServices: VentasService,
    private _facturasService: FacturasService,
    private _invoice2023Service: Invoice2023Service,
    private _clienteService: ClienteService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this._invoice2023Service.setIssuerData();
    this.limit = 10;
    this.obternerVentasPorFacturar(1, 10);
    this.formasDePago = this._facturasService.formasDePago;
    this.metodosDePagoFacturacion = this._facturasService.metodosDePago;
    this.usosCFDI = this._facturasService.usosCFDI;
  }


  public async facturarVenta4(venta) {
    console.log('***venta', venta);
   if (!venta.iva || venta?.iva === 0) {
     swal(
       'La venta no cuenta con IVA',
       'La Venta que intenta facturar no cuenta con iva, favor de actualizar la venta en Reporte de Ventas',
       'warning'
     );
   }

   venta.facturando = true;
   try {
     const result = (await this._invoice2023Service.createInvoice(venta)) as any;
     console.log('Facturacion response', result);
     venta.factura = result;
     await this._ventasServices.actualizarVenta(venta._id, venta).toPromise();
     venta.facturando = false;
     this.obternerVentasPorFacturar(this.paginaActual, 10);
     swal(
       'Venta Facturada Correctamente',
       `La factura a ${venta.cliente.nombre} por ${formatCurrency(venta.total, 'es-Mx', '$ ')} se generó correctamente`,
       'success'
     );
   } catch (error) {
     console.log('###Error al facturar', error);
     venta.facturando = false;
     swal(
       'Error al facturar Venta',
       error?.error?.Message || error || 'Error al facturar la venta',
       'error'
     );
   }
  }

  facturarVenta(venta) {
    venta.facturando = true;

    this._facturasService.facturarVenta(venta._id).subscribe(
      (resp: any) => {
        venta.facturando = false;
        this.obternerVentasPorFacturar(this.paginaActual, 10);
        swal(
          'Venta Facturada Correctamente',
          `La factura a ${venta.cliente.nombre} por ${formatCurrency(venta.total, 'es-Mx', '$ ')} se generó correctamente`,
          'success'
        );
      },
      (error) => {
        venta.facturando = false;
        swal(
          'Error al facturar Venta',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  obternerVentasPorFacturar(pagina, limit) {
    this._ventasServices.obtenerVentasSinFacturar(pagina, limit).subscribe(
      (resp: any) => {
        this.paginaActual = resp.paginaActual;
        this.totalPaginas = resp.totalPaginas;
        this.ventasPorFacturar = resp.ventasPorFacturar;
      },
      (error) => {
        swal(
          'Error al buscar ventas por facturar',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  actualizarVenta(venta) {
    this._ventasServices.actualizarVenta(venta._id, venta).subscribe(
      (resp: any) => {

      },
      (error) => {
        swal(
          'Error al actualizar venta',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }


}
