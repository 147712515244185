import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Cliente } from "../../../models/cliente.model";
import { UsuarioService } from '../../../services/usuarios/usuario.service';
import { ImageUploadService } from '../image-upload/image-upload.service';
import { EstadosCiudadesService } from 'src/app/estados-ciudades.service';
import { SegmentosService } from 'src/app/segmentos.service';
import {FiscalRegimeService} from '../../../services/fiscal-regime.service';
declare var $:any;


@Component({
  selector: "app-info-client",
  templateUrl: "./info-client.component.html",
  styleUrls: ["./info-client.component.css"]
})
export class InfoClientComponent implements OnInit, OnChanges {
  @Input()
  cliente: any = {};

  @Output()
  cambiosCliente: EventEmitter<any> = new EventEmitter();

  // Data
  estados = [];
  ciudades = [];
  segmentos = [];
  fiscalRegimes;

  editando: boolean = false;
  outAnimation: boolean = false;

  nombre: string;
  telefono: string;
  direccion: string;
  email: string;
  estado: string;
  ciudad: string;
  segmento: string;
  descuentoCliente: number;
  correoFacturacion: string;
  razonSocial: string;
  rfc: string;
  fiscalRegime: string;
  taxZipCode: string;

  nuevoEstado: string;
  nuevaCiudad: string;
  nuevoSegmento: string;
  nuevoNombre: string;
  nuevoTelefono: string;
  nuevaDireccion: string;
  nuevoCorreo: string;
  nuevoDescuentoCliente: number;
  nuevoCorreoFacturacion: string;
  nuevaRazonSocial: string;
  nuevoRFC: string;
  newFiscalRegime: string;
  newTaxZipCode: string;

  constructor(
    public _usuarioService: UsuarioService,
    public _imageUploadService: ImageUploadService,
    public _estadosCiudadesService: EstadosCiudadesService,
    public _segmentosService: SegmentosService,
    private _fiscalRegimeService: FiscalRegimeService,
  ) {
    this.estados = _estadosCiudadesService.estados;
    this.segmentos = _segmentosService.segmentos;
  }

  ngOnInit() {
    // Loading fiscal regimes
    this.fiscalRegimes = this._fiscalRegimeService.getFiscalRegimes();
  }

  cargarCiudades() {
    this.ciudades = this._estadosCiudadesService.estadosMunicipios[this.nuevoEstado].ciudades;
  }

  actualizarImagen( cliente ) {
    const subscriber = this._imageUploadService.notificacion.subscribe( (resp) => {

      if ('cliente' in resp) {
        this.cliente = resp.cliente;
      }

      subscriber.unsubscribe();
    });

    this._imageUploadService.inicializarModal('cliente', cliente._id);
    $('#infoCliente').modal('toggle');
    // $('#cargarImagen').modal('toggle');


  }

  resetearModal() {
    this.editando = false;
    this.outAnimation = false;
  }

  ngOnChanges() {
    this.nombre = this.cliente.nombre;
    this.telefono = this.cliente.telefono;
    this.direccion = this.cliente.direccion;
    this.email = this.cliente.email;
    this.estado = this.cliente.estado;
    this.ciudad = this.cliente.ciudad;
    this.segmento = this.cliente.segmento;
    this.descuentoCliente = this.cliente.descuentoCliente;
    this.correoFacturacion = this.cliente.correoFacturacion;
    this.razonSocial = this.cliente.razonSocial;
    this.rfc = this.cliente.rfc;
    const fiscalRegime = this.fiscalRegimes?.find(fr => fr.Value === this.cliente.fiscalRegime);
    this.fiscalRegime = fiscalRegime?.Name;
    this.newFiscalRegime = fiscalRegime?.Value;
    this.newTaxZipCode = this.cliente.taxZipCode;

  }

  asignarDescuentoSegmento() {
    this.nuevoDescuentoCliente = this._segmentosService.descuentosPorSegmento[this.nuevoSegmento];
  }

  guardarCambios() {
    this.nombre = this.nuevoNombre;
    this.telefono = this.nuevoTelefono;
    this.direccion = this.nuevaDireccion;
    this.email = this.nuevoCorreo;
    this.estado = this.nuevoEstado;
    this.ciudad = this.nuevaCiudad;
    this.segmento = this.nuevoSegmento;
    this.descuentoCliente = this.nuevoDescuentoCliente;
    this.correoFacturacion = this.nuevoCorreoFacturacion;
    this.razonSocial = this.nuevaRazonSocial;
    this.rfc = this.nuevoRFC;
    this.fiscalRegime = this.newFiscalRegime;
    this.taxZipCode = this.newTaxZipCode;

    const clienteActualizado = new Cliente(
      this.nuevoNombre,
      this.nuevoTelefono,
      this.nuevaDireccion,
      this.nuevoEstado,
      this.nuevaCiudad,
      this.nuevoSegmento,
      this.nuevoDescuentoCliente,
      this.nuevoCorreo,
      this.cliente.estatus,
      this.cliente.img,
      this.cliente._id,
      this.cliente.usuarioCreador,
      this._usuarioService.usuario._id,
      this.nuevoCorreoFacturacion,
      this.nuevaRazonSocial,
      this.nuevoRFC,
      this.newFiscalRegime,
      this.newTaxZipCode,
    );

    this.cambiosCliente.emit(clienteActualizado);

    this.cerrarEdicion();
  }

  cerrarEdicion() {
    this.outAnimation = true;

    setTimeout(() => {
      this.editando = false;
      this.outAnimation = false;
    }, 500);
  }

  cerrarInformacion() {
    this.outAnimation = true;

    setTimeout(() => {
      this.editando = true;
      this.outAnimation = false;
    }, 500);

    this.nuevoNombre = this.nombre;
    this.nuevoTelefono = this.telefono;
    this.nuevaDireccion = this.direccion;
    this.nuevoCorreo = this.email;
    this.nuevoEstado = this.estado;
    this.nuevaCiudad = this.ciudad;
    this.nuevoSegmento = this.segmento;
    this.nuevoDescuentoCliente = this.descuentoCliente;
    this.nuevoCorreoFacturacion = this.correoFacturacion;
    this.nuevaRazonSocial = this.razonSocial;
    this.nuevoRFC = this.rfc;

    this.cargarCiudades();
  }
}
