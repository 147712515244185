import { Component, OnInit } from '@angular/core';
import { ProductoService } from 'src/app/services/productos/producto.service';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css']
})
export class InventarioComponent implements OnInit {

  familias: any = [];

  constructor(
    private _productoService: ProductoService
  ) { }

  ngOnInit(): void {
    this.obtenerProductos();
  }

  obtenerProductos() {
    this._productoService.obtenerFamiliasYProductos().subscribe(
      (resp: any) => {
        this.familias = resp.familias;
      },
      (error) => {

      });
  }

}
