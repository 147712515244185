// Backend URL
export const URL_SERVICIOS = 'https://bessonartapi.digitalsystem.mx';

// Invoicing microservice config
export const MicroServicesAPI = 'https://officeapi.digitalsystem.mx';
export const ISSUER_RFC = 'BES190326K63';

// URL for download pdf and xml from facturama
export const FACTURAMA_DOWNLOAD_FILES_URL = 'https://api.facturama.mx/cfdi';
export const FACTURAMA_TOKEN ='Basic TWljaEZMOnhGNHhNTE1RcXNMVEx5NDpGYWN0dXJhbWFXZWI=';
