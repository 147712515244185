import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { FacturasService } from 'src/app/services/facturas.service';
import { UsuarioService } from 'src/app/services/usuarios/usuario.service';
import swal from 'sweetalert';


@Component({
  selector: 'app-notas-de-credito',
  templateUrl: './notas-de-credito.component.html',
  styleUrls: ['./notas-de-credito.component.css']
})
export class NotasDeCreditoComponent implements OnInit {

  // Variables
  urlservicios: string;
  token: string;

  terminoBusqueda: string;
  creandoNotaDeCredito = false;

  // Variables formulario
  nombreCliente: string;
  idCliente: string;
  formaDePago: string;
  monto: number;
  descripcion: string;


  // Data
  facturas: any;
  facturasRelacionadas = [];
  formasDePago = [];

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeFacturas: number;

  constructor(
    private _facturasService: FacturasService,
    private _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.formasDePago = this._facturasService.formasDePago;
    this.obtenerFacturas(1, 10);
    this.urlservicios = URL_SERVICIOS;
    this.token = this._usuarioService.token;
  }

  crearNotaDeCredito() {
    const uuids = this.facturasRelacionadas.map((factura) => factura.uuid );

    const notaDeCredito = {
      type: 'E',
      customer: this.idCliente,
      payment_form: this.formaDePago,
      relation: '01',
      related: uuids,
      product: {
        description: this.descripcion,
        price: this.monto
      }
    };
    this.creandoNotaDeCredito = true;
    this._facturasService.crearNotaDeCredito( notaDeCredito ).subscribe(
      (resp) => {
        this.creandoNotaDeCredito = false;
        swal(
          'Nota De Crédito Creada',
          'La nota de crédito fue creada exitosamente',
          'success'
        );
    },
    (error) => {
      this.creandoNotaDeCredito = false;
      swal(
        'Error al obtener facturas',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });


  }

  agregarFacturaARelacionadas( factura ) {

    if ( factura.type === 'E' ) {
      return;
    }

    const idFacturasAgregadas = this.facturasRelacionadas.map( (fact) => {
      return fact.id;
    });

    const clientesFacturasAgregadas = this.facturasRelacionadas.map( (fact) => {
      return fact.customer.id;
    });

    if ( clientesFacturasAgregadas.indexOf( factura.customer.id ) < 0 && clientesFacturasAgregadas.length > 0 ) {

      swal(
        'Factura de un cliente distinto',
        'La factura que desea agregar pertenece a un cliente distinto a una seleccionada previamente',
        'error'
      );

      return;

    }

    if (idFacturasAgregadas.indexOf(factura.id) >= 0) {
      swal(
        'Esta factura ya fue agregada',
        'La factura que desea agregar ya se encuentra en la lista de facturas relacionadas',
        'warning'
      );
      return;
    }

    if ( clientesFacturasAgregadas.length === 0 ) {
      this.idCliente = factura.customer.id;
      this.nombreCliente = factura.customer.legal_name;
    }

    this.facturasRelacionadas.push( factura );
  }

  removerFacturaDeRelacionadas( index ) {
    this.facturasRelacionadas.splice( index, 1 );
  }

  paginaAnterior() {
    if (this.paginaActual === 1) {
      return;
    }

    this.paginaActual -= 1;
    this.obtenerFacturas(this.paginaActual, 10);
  }

  paginaSiguiente() {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    this.paginaActual += 1;
    this.obtenerFacturas(this.paginaActual, 10);
  }

  obtenerFacturas(page: number, limit: number) {
    this._facturasService.obtenerFacturas(page, limit).subscribe(
      (resp: any) => {

        this.facturas = resp.facturas.data;
        this.paginaActual = resp.facturas.page;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;

      },
      (error) => {
        swal(
          'Error al obtener facturas',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  buscarFacturas() {
    let termino = this.terminoBusqueda;

    if (this.terminoBusqueda === '') {
      termino = 'sinTermino';
    }

    this._facturasService.buscarFacturas(1, 10, termino).subscribe(
      (resp: any) => {

        this.facturas = resp.facturas.data;
        this.paginaActual = resp.facturas.page;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;
      },
      (error) => {
        swal(
          'Error al buscar facturas',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

}
