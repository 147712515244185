import { Component, OnInit } from '@angular/core';
import { LogInventarioService } from 'src/app/services/logInventario/log-inventario.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import swal from 'sweetalert';


@Component({
  selector: 'app-ajuste-de-inventario',
  templateUrl: './ajuste-de-inventario.component.html',
  styleUrls: ['./ajuste-de-inventario.component.css']
})
export class AjusteDeInventarioComponent implements OnInit {

  // Variables de formulario
  tipoDeAjuste = '';
  cantidadDelAjuste: number;
  descripcionDelAjuste = '';

  // Buscador
  termino: string;
  productos: any[];
  productoSeleccionado: any = {};

  constructor(
    private _productoService: ProductoService,
    private _logInventarioService: LogInventarioService
  ) { }

  ngOnInit(): void {
  }

  realizarAjusteManual() {
    if ( this.tipoDeAjuste == ''  || this.descripcionDelAjuste == '' || !this.cantidadDelAjuste ) {
      swal(
        'Faltan campos por completar',
        'Faltan campos necesarios para realizar el ajuste',
        'warning'
        );
        return;
    }

    if ( this.cantidadDelAjuste === 0 ) {
      swal(
        'Cantidad de ajuste',
        'La cantidad a ajustar no puede ser 0',
        'warning'
      );
      return;
    }

    const log = {
      tipoDeMoviento: this.tipoDeAjuste,
      producto: this.productoSeleccionado._id,
      cantidad: this.cantidadDelAjuste,
      descripcion: this.descripcionDelAjuste
    };

    if (this.tipoDeAjuste === 'Baja de Inventario - Manual' && log.cantidad > 0 ) {
      log.cantidad = log.cantidad * - 1;
    }
    const productoActualizado = {
      _id: this.productoSeleccionado._id,
      cantidad: log.cantidad
    };

    this._productoService.actualizarProducto( productoActualizado ).subscribe(
      (reps: any) => {

        this._logInventarioService.crearLogDeInventario(log).subscribe(
          (resp2: any) => {
            this.actualizarProductoSeleccionado();
            this.resetearFormulario();
            swal(
              'Ajuste de inventario exitoso',
              'El ajuste de inventario se realizó correctamente',
              'success'
            );
          },
          (error) => {
            swal(
              'Error al registrar ajuste de inventario',
              error.error.mensaje + ' | ' + error.error.errors.message,
              'error'
            );
          });
    },
    (error) => {
      swal(
        'Error al actualizar inventario',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });


  }

  cargarInformacionDeProducto( producto ) {
    this.productoSeleccionado = producto;
    this.productos = null;
  }

  buscarProductos() {
    const termino = this.termino;

    if (termino === '') {
      this.productos = null;
      this.productoSeleccionado = {};
      return;
    }

    this._productoService.buscarProducto(termino).subscribe(
      (resp: any) => {
        this.productos = resp.producto;
      },
      (error) => {
        swal(
          'Error al buscar productos',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  actualizarProductoSeleccionado() {
    this._productoService.buscarProductoPorCodigo( this.productoSeleccionado.codigo ).subscribe(
      (resp: any) => {
      this.productoSeleccionado = resp.productos[0];
    },
    (error) => {
      swal(
        'Error al refrescar la información del producto',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  resetearFormulario() {
    this.tipoDeAjuste = '';
    this.cantidadDelAjuste = null;
    this. descripcionDelAjuste = '';
  }

}
