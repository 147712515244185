import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Producto } from 'src/app/models/producto.model';
import { UsuarioService } from '../usuarios/usuario.service';
import { URL_SERVICIOS } from 'src/app/config/config';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  // Data
  familias: any[] = [
    { nombre: 'Bisagras' },
    { nombre: 'Sistema de apertura para Alacena' },
    { nombre: 'Cajones ( Correderas )'},
    { nombre: 'Servo-Drive' },
    { nombre: 'Equipo para instalación de herrajes' },
    { nombre: 'Otros Productos' },
    { nombre: 'Organizadores para Cajones'}
  ];

  categorizacion = {
      Bisagras: {
          Accesorios: {
            'Bases para Bisagra': 'Bases para Bisagra',
            'Distanciador de Cazoleta': 'Distanciador de Cazoleta',
            'Tapas para bisagras': 'Tapas para bisagras',
            'Tornillos y Brocas': 'Tornillos y Brocas'
          },
          'BLUMOTION': 'BLUMOTION',
          'Clip 100°': 'Clip 100°',
          'CLIP Top': {
            'Clip Top Negro Ónix': {
              '110° con BLUMOTION': '110° con BLUMOTION',
              '155° de 0 obstrucción': '155° de 0 obstrucción',
              'Accesorios Clip Top Negro Ónix': 'Accesorios Clip Top Negro Ónix',
              'Cristallo 110° para puertas de cristal y/o espejo': 'Cristallo 110° para puertas de cristal y/o espejo'
            },
            'Clip Top Níquel': {
              '110° con BLUMOTION': '110° con BLUMOTION',
              '110° sin BLUMOTION': '110° sin BLUMOTION',
              '110° para mueble de esquina': '110° para mueble de esquina',
              '155° de 0 obstrucción': '155° de 0 obstrucción',
              '170°': '170°',
              '60° para esquina con puerta plegable': '60° para esquina con puerta plegable',
              '94° para puertas de cristal': '94° para puertas de cristal',
              '95° con BLUMOTION para puertas con marco de aluminio angosto': '95° con BLUMOTION para puertas con marco de aluminio angosto',
              '95° para puertas gruesas': '95° para puertas gruesas',
              '95° para rincones falsos': '95° para rincones falsos',
              'Cristallo para puertas de cristal y/o espejo': 'Cristallo para puertas de cristal y/o espejo',
              'Mini de 94°': 'Mini de 94°'
            }
          },
          'Expando T': 'Expando T',
          'Modul': 'Modul',
          'Plantillas': 'Plantillas',
          'Tip On': 'Tip On'
      },
      'Sistema de apertura para Alacena': {
          'Avento HK Top': 'Avento HK Top',
          'Avento HK-XS': 'Avento HK-XS',
          'Avento HL': 'Avento HL',
          'Avento HS': 'Avento HS',
          'Aventos HF': 'Aventos HF',
          'Aventos HK/HK-S': {
            'Aventos HK': 'Aventos HK',
            'Aventos HK tip on': 'Aventos HK tip on',
            'Aventos HK-S': 'Aventos HK-S',
            'Aventos HK-S tip on': 'Aventos HK-S tip on'
          }
      },
    'Cajones ( Correderas )': {
        'Antaro': {
          'Blanco': {
            'Antaro Altura C con Galería': 'Antaro Altura C con Galería',
            'Antaro Altura C con Galería e Inserción': 'Antaro Altura C con Galería e Inserción',
            'Antaro Altura D con Galería': 'Antaro Altura D con Galería',
            'Antaro Altura D con Galería e Inserción en Metal': 'Antaro Altura D con Galería e Inserción en Metal',
            'Antaro Altura M': 'Antaro Altura M',
            'Antaro Bajo Fregadero Altura D con Galerías e Inserción': 'Antaro Bajo Fregadero Altura D con Galerías e Inserción',
            'Antaro Botellero': 'Antaro Botellero',
            'Antaro Interno Altura C con Galería': 'Antaro Interno Altura C con Galería',
            'Antaro Interno Altura C con Galería e Inserción': 'Antaro Interno Altura C con Galería e Inserción',
            'Antaro Interno Altura D con Galería': 'Antaro Interno Altura D con Galería',
            'Antaro Interno Altura D con Galería e Inserción en Metal': 'Antaro Interno Altura D con Galería e Inserción en Metal',
            'Antaro Interno Altura M': 'Antaro Interno Altura M',
            'Space Corner Altura D con Syncromotion': 'Space Corner Altura D con Syncromotion',
            'Space Corner Altura D sin Syncromotion': 'Space Corner Altura D sin Syncromotion',
            'Space Corner Altura M con Syncromotion': 'Space Corner Altura M con Syncromotion',
            'Space Corner Altura M sin Syncromotion': 'Space Corner Altura M sin Syncromotion',
            'Space Tower Gabinete Despensa': 'Space Tower Gabinete Despensa'
          },
          'Gris': {
            'Anatro Altura D con Galería e Inserción en Metal': 'Anatro Altura D con Galería e Inserción en Metal',
            'Antaro Altura C con Galería': 'Antaro Altura C con Galería',
            'Antaro Altura C con Galería e Inserción': 'Antaro Altura C con Galería e Inserción',
            'Antaro Altura D con Galería': 'Antaro Altura D con Galería',
            'Antaro Altura M': 'Antaro Altura M',
            'Antaro Bajo Fregadero Altura D con Galería e Inserción': 'Antaro Bajo Fregadero Altura D con Galería e Inserción',
            'Antaro Botellero': 'Antaro Botellero',
            'Antaro Interno Altura C con Galería': 'Antaro Interno Altura C con Galería',
            'Antaro Interno Altura C con Galería e Inserción': 'Antaro Interno Altura C con Galería e Inserción',
            'Antaro Interno Altura D con Galería': 'Antaro Interno Altura D con Galería',
            'Antaro Interno Altura D con Galería e Inserción en Metal': 'Antaro Interno Altura D con Galería e Inserción en Metal',
            'Antaro Interno Altura M': 'Antaro Interno Altura M',
            'Space Corner Altura D con Syncromotion': 'Space Corner Altura D con Syncromotion',
            'Space Corner altura D sin Syncromotion': 'Space Corner altura D sin Syncromotion',
            'Space Corner Altura M con Syncromotion': 'Space Corner Altura M con Syncromotion',
            'Space Corner Altura M sin Syncromotion': 'Space Corner Altura M sin Syncromotion',
            'Space Tower Gabinete Despensa': 'Space Tower Gabinete Despensa'
          },
          'Negro': {
            'Cajón altura "M"': 'Cajón altura "M"',
            'Cajón Interno altura "M"': 'Cajón Interno altura "M"',
            'Cajón altura "C"': 'Cajón altura "C"',
            'Cajón Interno altura "C"': 'Cajón Interno altura "C"',
            'Cajón altura "D"': 'Cajón altura "D"',
            'Cajón Interno altura "D"': 'Cajón Interno altura "D"'
          }
        },
        'Legrabox': {
          'Legrabox Inox': {
            'Botellero Legrabox Inox': 'Botellero Legrabox Inox',
            'Legrabox free altura C Inox': 'Legrabox free altura C Inox',
            'Legrabox free altura C interno Inox (Alto)': 'Legrabox free altura C interno Inox (Alto)',
            'Legrabox free altura C interno Inox (Corto)': 'Legrabox free altura C interno Inox (Corto)',
            'Legrabox free altura C interno Inox con galería': 'Legrabox free altura C interno Inox con galería',
            'Legrabox pure altura C con galería frontal Inox': 'Legrabox pure altura C con galería frontal Inox',
            'Legrabox pure altura C Inox': 'Legrabox pure altura C Inox',
            'Legrabox pure altura C interno inox (Diseño Alto)': 'Legrabox pure altura C interno inox (Diseño Alto)',
            'Legrabox pure altura C interno inox (Diseño Corto)': 'Legrabox pure altura C interno inox (Diseño Corto)',
            'Legrabox pure altura M Inox': 'Legrabox pure altura M Inox',
            'Legrabox pure altura M interno Inox': 'Legrabox pure altura M interno Inox',
            'Legrabox pure Inox altura F': 'Legrabox pure Inox altura F',
            'Space Tower': 'Space Tower'
          },
          'Legrabox Negro': {
            'Legrabox Pure Altura C Interno (Corto)': 'Legrabox Pure Altura C Interno (Corto)',
            'Legrabox Pure Altura C Interno Negro con Galería Frontal': 'Legrabox Pure Altura C Interno Negro con Galería Frontal',
            'Legrabox Pure Altura C Negro': 'Legrabox Pure Altura C Negro',
            'Legrabox Pure Altura F Negro': 'Legrabox Pure Altura F Negro',
            'Legrabox Pure Altura M Interno Negro': 'Legrabox Pure Altura M Interno Negro',
            'Legrabox Pure Altura M Negro': 'Legrabox Pure Altura M Negro'

          },
          'Legrabox Orión': {
            'Botellero Legrabox Gris Orión': 'Botellero Legrabox Gris Orión',
            'Legrabox Free Altura C Gris Orión': 'Legrabox Free Altura C Gris Orión',
            'Legrabox Free Altura C Interno con Galería Gris Orión': 'Legrabox Free Altura C Interno con Galería Gris Orión',
            'Legrabox Free Altura C Interno Gris Orión (Alto)': 'Legrabox Free Altura C Interno Gris Orión (Alto)',
            'Legrabox Free Altura C Interno Gris Orión (Corto)': 'Legrabox Free Altura C Interno Gris Orión (Corto)',
            'Legrabox Pure Altura "F" Gris Orión': 'Legrabox Pure Altura "F" Gris Orión',
            'Legrabox Pure altura C galería frontal Gris Orión': 'Legrabox Pure altura C galería frontal Gris Orión',
            'Legrabox Pure Altura C Gris Orión': 'Legrabox Pure Altura C Gris Orión',
            'Legrabox Pure Altura C Interno Gris Orión (Corto)': 'Legrabox Pure Altura C Interno Gris Orión (Corto)',
            'Legrabox Pure Altura M Gris Orión': 'Legrabox Pure Altura M Gris Orión',
            'Legrabox Pure Altura M Interno Gris Orión': 'Legrabox Pure Altura M Interno Gris Orión',
            'Legrabox Pure Atura C Interno Gris Orión (Alto)': 'Legrabox Pure Atura C Interno Gris Orión (Alto)',
            'Space Tower': 'Space Tower'
          }
        },
        'Metabox': {
          'Metabox Blanco Crema': {
            'Cajón altura H': 'Cajón altura H',
            'Cajón altura K': 'Cajón altura K',
            'Cajón altura M': {
              'Cajón Altura M Interno': 'Cajón Altura M Interno',
              'Cajón altura M Regular': 'Cajón altura M Regular'
            },
            'Cajón con Galería': {
              'Cajón Altura B con Galería': 'Cajón Altura B con Galería',
              'Cajón Altura D con Doble Galería': 'Cajón Altura D con Doble Galería'
            }
          },
          'Metabox Gris': {
            'Cajón Altura H': 'Cajón Altura H',
            'Cajón Altura M': {
              'Cajón Altura M Interno': 'Cajón Altura M Interno',
              'Cajón Altura M Regular': 'Cajón Altura M Regular'
            },
            'Cajón con Galería': {
              'Cajón Altura B con Galería': 'Cajón Altura B con Galería',
              'Cajón Altura D con Doble Galería': 'Cajón Altura D con Doble Galería'
            }
          }
        },
        'Movento': {
          'Movento Blumotion': 'Movento Blumotion',
          'Movento Montaje a Piso': 'Movento Montaje a Piso',
          'Movento Tip-On': 'Movento Tip-On',
          'Movento Tip-On Blumotion': 'Movento Tip-On Blumotion'
        },
        'Space Step': 'Space Step',
        'Tandem': 'Tandem',
        'Tip On BLUMOTION': {
          'Antaro': 'Antaro',
          'Legrabox': 'Legrabox',
          'Movento': 'Movento'
        },
        'Tope de repisa': 'Tope de repisa'
    },
    'Servo-Drive': {
        'Servo Drive': 'Servo Drive',
        'Servo Drive Flex': 'Servo Drive Flex',
        'Servo Drive Uno': 'Servo Drive Uno',
    },
    'Equipo para instalación de herrajes': {
        'Calibres y plantillas': 'Calibres y plantillas',
        'Dispositivos de Montaje': 'Dispositivos de Montaje',
        'Maquinaria': 'Maquinaria'
    },
    'Otros Productos': 'Otros Productos',
    'Organizadores para Cajones': {
        'Ambia-Line Accesorios': 'Ambia-Line Accesorios',
        'Ambia-Line para Legrabox': 'Ambia-Line para Legrabox',
        'Orga-Line Accesorios': 'Orga-Line Accesorios',
        'Orga-Line para Tandembox': 'Orga-Line para Tandembox'
    }
  };


  categoriasFinales: any[] = [
    'Bases para Bisagra',
    'Distanciador de Cazoleta',
    'Tapas para bisagras',
    'Tornillos y Brocas'
  ];

  constructor(
    public http: HttpClient,
    public _usuarioService: UsuarioService
  ) { }

  public getProductSatCode(productId: string) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/satCode/${ productId }?token=${ token }`;
    return this.http.get( url );
  }
  obtenerProductosPorSurtir() {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/porSurtir?token=${ token }`;

    return this.http.get( url );
  }

  obtenerProductosPaginados( pagina, limit = 10 ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto?token=${ token }&pagina=${ pagina }&limit=${ limit }`;

    return this.http.get( url );
  }

  obtenerProductosPorCategoria( categoria: string ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/categoria/${ categoria }?token=${ token }`;

    return this.http.get( url );
  }

  actualizarProducto( producto ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/${ producto._id }?token=${ token }`;

    return this.http.put( url, producto );
  }

  salidaDeInventario( productId, cantidad: number ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/salidaInventario/${ productId }?token=${ token }`;

    return this.http.put(url, { cantidadSurtida: cantidad } );
  }

  registrarProducto( producto: Producto) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto?token=${ token }`;

      return this.http.post( url, producto );
  }

  obtenerProductosPorFamilia( familia: string, pagina: number = 1 ) {
    const token = this._usuarioService.token;
    const desde = (pagina - 1) * 10;
    const url = URL_SERVICIOS + `/producto/familia/${ familia }?token=${ token }&desde=${ desde }`;

    return this.http.get( url );

  }

  obtenerFamiliasYProductos() {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/familiasYProductos?token=${token}`;

    return this.http.get( url );
  }

  buscarProductoPorCodigo( codigo: string ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/codigo/${ codigo }?token=${ token }`;

    return this.http.get( url );
  }

  eliminarProductoPorId(id: string) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/producto/${ id }?token=${ token }`;

    return this.http.delete( url );
  }

  buscarProducto(termino) {
    const token = this._usuarioService.token;

    const url = URL_SERVICIOS + '/busqueda/coleccion/producto/' + termino + '?token=' + token;

    return this.http.get(url);
  }

}
