import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { CrmComponent } from './crm/crm.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { LoginGuardGuard } from '../services/guards/login-guard.guard';
import { CatalogoProductosComponent } from './catalogo-productos/catalogo-productos.component';
import { ProfileComponent } from './profile/profile.component';
import { ReporteVentasComponent } from './reporte-ventas/reporte-ventas.component';
import { CuentasPorCobrarComponent } from './cuentas-por-cobrar/cuentas-por-cobrar.component';
import { RequisicionesComponent } from './requisiciones/requisiciones.component';
import { ComprasComponent } from './compras/compras.component';
import { AprobacionRequisicionesComponent } from './aprobacion-requisiciones/aprobacion-requisiciones.component';
import { CuentasPorPagarComponent } from './cuentas-por-pagar/cuentas-por-pagar.component';
import { PermisosDeUsuariosComponent } from './permisos-de-usuarios/permisos-de-usuarios.component';
import { RegistroGastosComponent } from './registro-gastos/registro-gastos.component';
import { NominaComponent } from './nomina/nomina.component';
import { ReporteGastosComponent } from './reporte-gastos/reporte-gastos.component';
import { ConfiguracionMenuUsuariosComponent } from './configuracion-menu-usuarios/configuracion-menu-usuarios.component';
import { GestionEmpleadosComponent } from './gestion-empleados/gestion-empleados.component';
import { CambioPasswordComponent } from './cambio-password/cambio-password.component';
import { InventarioTiendaComponent } from '../inventario-tienda/inventario-tienda.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { EscaneoProductosComponent } from './escaneo-productos/escaneo-productos.component';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { FacturarVentasComponent } from './facturar-ventas/facturar-ventas.component';
import { CancelarFacturasComponent } from './cancelar-facturas/cancelar-facturas.component';
import { NotasDeCreditoComponent } from './notas-de-credito/notas-de-credito.component';
import { FacturasPagoParcialComponent } from './facturas-pago-parcial/facturas-pago-parcial.component';
import { LogsDeInventarioComponent } from './logs-de-inventario/logs-de-inventario.component';
import { SurtidoProductosComponent } from './surtido-productos/surtido-productos.component';
import { InventarioComponent } from './inventario/inventario.component';
import { AjusteDeInventarioComponent } from './ajuste-de-inventario/ajuste-de-inventario.component';
import { PuntoDeReordenComponent } from './punto-de-reorden/punto-de-reorden.component';
import {AddCsdComponent} from './add-csd/add-csd.component';


const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [ LoginGuardGuard ],
        children: [
            { path: 'dashboard', component: DashboardComponent },
            { path: 'progress', component: ProgressComponent },
            { path: 'catalogoProductos', component: CatalogoProductosComponent},
            { path: 'graficas1', component: Graficas1Component },
            { path: 'crm', component: CrmComponent },
            { path: 'calendario', component: CalendarioComponent },
            { path: 'colaboradores', component: UsuariosComponent },
            { path: 'perfil', component: ProfileComponent},
            { path: 'reporteVentas', component: ReporteVentasComponent },
            { path: 'cuentasPorCobrar', component: CuentasPorCobrarComponent },
            { path: 'requisiciones', component: RequisicionesComponent },
            { path: 'compras', component: ComprasComponent},
            { path: 'aprobaciones', component: AprobacionRequisicionesComponent },
            { path: 'cuentasPorPagar', component: CuentasPorPagarComponent },
            { path: 'permisosDeUsuario', component: PermisosDeUsuariosComponent },
            { path: 'gastos', component: RegistroGastosComponent },
            { path: 'nomina', component: NominaComponent },
            { path: 'reporteDeGastos', component: ReporteGastosComponent},
            { path: 'configuracionDeMenu', component: ConfiguracionMenuUsuariosComponent },
            { path: 'usuarios', component: GestionEmpleadosComponent },
            { path: 'cambiarPassword', component: CambioPasswordComponent },
            { path: 'inventario', component: InventarioComponent },
            { path: 'proveedores', component: ProveedoresComponent },
            { path: 'productScan', component: EscaneoProductosComponent },
            { path: 'facturacion', component: FacturacionComponent },
            { path: 'facturar', component: FacturarVentasComponent },
            { path: 'cancelarFacturas', component: CancelarFacturasComponent },
            { path: 'notasDeCredito', component: NotasDeCreditoComponent },
            { path: 'recepcionDePago', component: FacturasPagoParcialComponent },
            { path: 'logInventario', component: LogsDeInventarioComponent},
            { path: 'surtidoDeProductos', component: SurtidoProductosComponent },
            { path: 'ajustesDeInventario', component: AjusteDeInventarioComponent },
            { path: 'puntoDeReorden', component: PuntoDeReordenComponent },
            { path: 'sellosDigitales', component: AddCsdComponent },
            { path: '', redirectTo: '/reporteVentas', pathMatch: 'full' }
        ]
    },
];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
