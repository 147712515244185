import { Component, OnInit } from '@angular/core';
import { Producto } from 'src/app/models/producto.model';
import { ProductosBlumService } from 'src/app/services/productos-blum.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-escaneo-productos',
  templateUrl: './escaneo-productos.component.html',
  styleUrls: ['./escaneo-productos.component.css']
})
export class EscaneoProductosComponent implements OnInit {

  // Variables
  idProductoEscaneado = '';
  productoActual: any = { };
  idProductoBaseDeDatos: string;

  constructor(
    private _productosBlum: ProductosBlumService,
    private _productoService: ProductoService
  ) { }

  ngOnInit(): void {
  }

  actualizarCantidadEnInventario( cantidadEnInventario: number ) {
    this.productoActual.cantidad = cantidadEnInventario;
  }

  buscarProductoPorCodigo( id = this.idProductoEscaneado ) {
    this._productosBlum.buscarProductoBlumPorEAN( id ).subscribe(
      (resp: any) => {
        this.productoActual = resp.producto;
        this.validarProductoEnBaseDeDatos( id );
        this.idProductoEscaneado = '';

    },
    error => {

      this.productoActual = { };
      this.idProductoEscaneado = '';

    });

  }

  validarProductoEnBaseDeDatos( codigoEAN: string ) {
    this._productoService.buscarProductoPorCodigo( codigoEAN ).subscribe(
      (resp: any) => {

        this.idProductoBaseDeDatos = resp.productos[0]._id;
        this.productoActual._id = resp.productos[0]._id;
        this.productoActual.productoDadoDeAlta = true;
        this.productoActual.cantidad = resp.productos[0].cantidad;
        this.productoActual.rutaDeCategorias = resp.productos[0].rutaDeCategorias;

    }, (error) => {

      this.productoActual.productoDadoDeAlta = false;

    });
  }

  cleanInput() {
    this.idProductoEscaneado = '';
  }
}
