import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriasGastoService {

  categoriasGasto = [
    'Blum MX',
    'Proveedores Productos',
    'Proveedores Materia Prima',
    'Proveedores Maquila',
    'Consumibles',
    'Nómina',
    'Otros',
    'Fletes',
    'Publicidad',
    'Gastos no Operativos',
    'Comisiones por Ventas',
    'Impuestos',
    'Transporte',
    'Maquinaria/Equipo',
    'Mantenimiento',
    'Servicios/Rentas'
  ];

  listaGastosOperativos = [
      'Blum MX',
      'Proveedores Productos',
      'Proveedores Materia Prima',
      'Proveedores Maquila',
      'Consumibles',
      'Nómina',
      'Otros',
      'Fletes',
      'Publicidad',
      'Comisiones por Ventas',
      'Transporte',
      'Maquinaria/Equipo',
      'Mantenimiento',
      'Servicios/Rentas'
    ];

  constructor() { }
}
