export class Cliente {

    constructor(
        public nombre: string,
        public telefono: string,
        public direccion: string,
        public estado: string,
        public ciudad: string,
        public segmento: string,
        public descuentoCliente: number,
        public email?: string,
        public estatus?: string,
        public img?: string,
        public _id?: string,
        public usuarioCreador?: any,
        public usuarioUltimaModificacion?: any,
        public correoFacturacion?: string,
        public razonSocial?: string,
        public rfc?: string,
        public fiscalRegime?: string,
        public taxZipCode?: string
        ) { }


}
