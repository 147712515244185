import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-info-venta',
  templateUrl: './modal-info-venta.component.html',
  styleUrls: ['./modal-info-venta.component.css']
})
export class ModalInfoVentaComponent implements OnInit, OnChanges {

  @Input()
  venta: any;

  // Variables de formulario
  fechaString: string;

  fecha: Date = new Date();

  constructor() { }

  ngOnInit(): void {
  }

  resetearModal() {

  }

  ngOnChanges(changes): void {
      if (this.venta) {
        this.fecha = new Date(this.venta.fecha);
        this.cargarFechaString();
      }

  }

  cargarFechaString() {

    let year = this.fecha.getFullYear();
    let mes = this.fecha.getMonth();
    let dia = this.fecha.getDate();
    mes = mes + 1;
    let mesString: string;
    let diaString: string;

    if (mes < 10) {
      mesString = '0' + mes;
    } else {
      mesString = String(mes);
    }

    if (dia < 10) {
      diaString = '0' + dia;
    } else {
      diaString = String(dia);
    }


    this.fechaString = `${year}-${mesString}-${diaString}`;

  }

}
