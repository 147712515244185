import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LogInventarioService } from 'src/app/services/logInventario/log-inventario.service';
import { ProductoService } from 'src/app/services/productos/producto.service';
import swal from 'sweetalert';
declare var $: any;

@Component({
  selector: 'app-alta-de-inventario',
  templateUrl: './alta-de-inventario.component.html',
  styleUrls: ['./alta-de-inventario.component.css']
})
export class AltaDeInventarioComponent implements OnInit {

  @Input()
  productId: string;

  @Output()
  actualizarProducto: EventEmitter<any> = new EventEmitter();
  validarProductoEnBaseDeDatos: EventEmitter<any> = new EventEmitter();

  // Variables
  cantidad = null;

  constructor(
    private _productoService: ProductoService,
    private _logInventarioService: LogInventarioService
  ) { }

  ngOnInit(): void {
  }

  cargarProductosAInventario() {

    if ( this.cantidad === 0 ) {

      return;
    }

    const productoActualizado = {
      cantidad: this.cantidad,
      _id: this.productId
    };

    this._productoService.actualizarProducto( productoActualizado ).subscribe(
      (resp: any) => {

        const log = {
          tipoDeMoviento: 'Alta de Inventario',
          producto: productoActualizado._id,
          cantidad: productoActualizado.cantidad,
          venta: null,
          descripcion: 'Recepción de producto'
        };

        this._logInventarioService.crearLogDeInventario( log ).subscribe(
          (resp2: any) => {

            this.actualizarProducto.emit(resp.producto.cantidad);

            $('#modalAltaDeInventario').modal('toggle');

            swal(
              'Inventario actualizado exitosamente',
              'Se ha registrado en inventario la cantidad ingresada',
              'success'
            );

            this.resetearModal();
        },
        (error) => {
          swal(
            'Error al crear registro de inventario',
            error.error.mensaje + ' | ' + error.error.errors.message,
            'error'
          );
        });

        

    },
    (error) => {
      swal(
        'Error al actualizar inventario del producto',
        error.error.mensaje + ' | ' + error.error.errors.message,
        'error'
      );
    });
  }

  resetearModal() {
    this.cantidad = null;
  }

}
