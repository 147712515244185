import { Component, OnInit } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/config';
import { FacturasService } from 'src/app/services/facturas.service';
import { UsuarioService } from 'src/app/services/usuarios/usuario.service';
import swal from 'sweetalert';

@Component({
  selector: 'app-facturas-pago-parcial',
  templateUrl: './facturas-pago-parcial.component.html',
  styleUrls: ['./facturas-pago-parcial.component.css']
})
export class FacturasPagoParcialComponent implements OnInit {

  // Variables de formulario
  nombreCliente: string;
  idCliente: string;
  pagoRecibido = 0;
  formaDePago: string;
  formaDePagoValue: string;


  // Variables
  urlservicios: string;
  token: string;

  // Data
  facturasPPD: any[];
  facturasP: any[];
  facturasRelacionadas = [];
  facturasPaginaActual: any;
  installmentYBalance: any[];
  formasDePago: any[];

  // Paginado
  paginaActual: number;
  totalPages: number;
  totalDeFacturas: number;

  terminoBusqueda: string;
  aplicandoPago = false;

  constructor(
    private _facturasService: FacturasService,
    private _usuarioService: UsuarioService
  ) { }

  ngOnInit(): void {
    this.paginaActual = 1;
    this.obtenerFacturasPagoParcial();
    this.obtenerFacturasDeRecepcionDePago();
    this.urlservicios = URL_SERVICIOS;
    this.token = this._usuarioService.token;
    this.formasDePago = this._facturasService.formasDePago;
  }

  obtenerFacturasDeRecepcionDePago() {
    this._facturasService.obtenerFacturasPorType( 'P' ).subscribe(
      (resp: any) => {
        this.facturasP = resp.facturas.data;
    }, (error) => {
        swal(
          'Error al obtener facturas',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
    });

  }

  registrarPagoEnFactura( evento, i ) {
    this.facturasRelacionadas[i].aplicarPago = Number(evento.target.value);
    this.calcularTotalPagoRecibido();
  }

  calcularTotalPagoRecibido() {
    this.pagoRecibido = 0;
    this.facturasRelacionadas.forEach( (factura) => {
      this.pagoRecibido += (factura.aplicarPago) ? factura.aplicarPago : 0;
    });
  }

  cargarFacturasPaginaActual( pagina ) {
    this.facturasPaginaActual = this.facturasPPD.slice( ( 10 * (pagina - 1) ), pagina * 10 );
  }

  agregarFacturaARelacionadas( factura ) {

    const idFacturasAgregadas = this.facturasRelacionadas.map((fact) => {
      return fact.id;
    });

    const clientesFacturasAgregadas = this.facturasRelacionadas.map((fact) => {
      return fact.customer.id;
    });

    if (clientesFacturasAgregadas.indexOf(factura.customer.id) < 0 && clientesFacturasAgregadas.length > 0) {

      swal(
        'Factura de un cliente distinto',
        'La factura que desea agregar pertenece a un cliente distinto a una seleccionada previamente',
        'error'
      );

      return;

    }

    if (idFacturasAgregadas.indexOf(factura.id) >= 0) {
      swal(
        'Esta factura ya fue agregada',
        'La factura que desea agregar ya se encuentra en la lista de facturas relacionadas',
        'warning'
      );
      return;
    }

    if ( clientesFacturasAgregadas.length === 0 ) {
      this.idCliente = factura.customer.id;
      this.nombreCliente = factura.customer.legal_name;
    }

    this.facturasRelacionadas.push(factura);
    this.calcularInstallmentYLastBalance();
  }

  removerFacturaDeRelacionadas(index) {
    this.facturasRelacionadas[index].aplicarPago = 0;
    this.facturasRelacionadas.splice(index, 1);
    this.calcularTotalPagoRecibido();
  }

  obtenerFacturasPagoParcial( ) {
    this._facturasService.obtenerFacturasPorType( 'PPD' ).subscribe(
      (resp: any) => {

        this.facturasPPD = resp.facturas.data;
        this.totalPages = resp.facturas.total_pages;
        this.totalDeFacturas = resp.facturas.total_results;

        this.cargarFacturasPaginaActual( 1 );

      },
      (error) => {
        swal(
          'Error al obtener facturas',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
      });
  }

  buscarFacturas() {
    let termino = this.terminoBusqueda;

    if (this.terminoBusqueda === '') {
      termino = 'sinTermino';
      this.cargarFacturasPaginaActual( 1 );
      return;
    }

    const resultadosBusqueda = this.facturasPPD.filter( (factura) => {

      if ( factura.customer.legal_name.search( RegExp( this.terminoBusqueda, 'i' ) ) >= 0 ||
          factura.customer.tax_id.search( RegExp( this.terminoBusqueda, 'i' ) ) >= 0 ) {
        return factura;
       } else {
         return false;
       }
    });

    this.facturasPaginaActual = resultadosBusqueda;
  }

  paginaAnterior() {
    if (this.paginaActual === 1) {
      return;
    }

    this.paginaActual -= 1;
    this.cargarFacturasPaginaActual( this.paginaActual );
  }

  paginaSiguiente() {
    if (this.paginaActual === this.totalPages) {
      return;
    }

    this.paginaActual += 1;
    this.cargarFacturasPaginaActual( this.paginaActual );
  }

  calcularInstallmentYLastBalance() {
    this.installmentYBalance = [];
    this.obtenerFacturasDeRecepcionDePago();
    const facturasDePago = this.facturasP;

    let installmentObject;
    const installmentArray = [];

    this.facturasRelacionadas.forEach( (facturaRelacionada) => {

      const installmentCalculate = facturasDePago.map( ( facturaAnalizada) => {

        installmentObject = facturaAnalizada.payments[0].related.map(  ( pago ) => {
          let installment = 0;
          let totalPagos = 0;

          if ( pago.uuid === facturaRelacionada.uuid ) {
            installment += 1;
            totalPagos += pago.amount;
          }

          return { installment, totalPagos };
        });

        // Reducimos los pagos dentro de una factura de recepcion de pago a 1 objeto
        const subfinalObject = installmentObject.reduce( (reducedObject, iterationObject) => {

          reducedObject.installment += iterationObject.installment;
          reducedObject.totalPagos += iterationObject.totalPagos;

          return reducedObject;
        }, { installment: 0, totalPagos: 0 });

        return subfinalObject;

      });

      // Reducimos la sumatoria de los totales de las facturas de pago
      const finalObject = installmentCalculate.reduce( (reducing, iterating) => {
        reducing.installment += iterating.installment;
        reducing.totalPagos += iterating.totalPagos;
        return reducing;
      }, { installment: 0, totalPagos: 0 });
      this.installmentYBalance.push(finalObject);
    });

    return this.installmentYBalance;

  }

  crearRecepcionDePago() {
    let peticionIncorrecta = false;

    if (this.facturasRelacionadas.length === 0) {
      swal(
        'No se han seleccionado facturas',
        'Favor de seleccionar la o las facturas a las que se aplicará el pago',
        'warning'
      );

      return;
    }

    if (!this.formaDePagoValue) {
      swal(
        'Forma de Pago',
        'Favor de seleccionar una forma de pago',
        'warning'
      );
      return;
    }

    this.facturasRelacionadas.forEach( (factura) => {

      if (!factura.aplicarPago || factura.aplicarPago === 0 ) {

        swal(
          'Revisar montos a aplicar',
          'Favor de revisar los montos a aplicar, no deben ser campos en blanco y su valor debe ser distinto de cero',
          'warning'
        );
        peticionIncorrecta = true;
      }
    });

    this.facturasRelacionadas.forEach((factura, i) => {
      if ( (this.installmentYBalance[i].totalPagos + factura.aplicarPago) > factura.total) {
        swal(
          'Monto superior a total factura',
          'Favor de Revisar los montos a aplicar, alguno de ellos es superior al total de la factura',
          'warning'
        );
        peticionIncorrecta = true;
      }
    });

    if (peticionIncorrecta) {
      return;
    }

    const arregloInstallmentPago = this.calcularInstallmentYLastBalance();

    let related;

      related = this.facturasRelacionadas.map((factura, i) => {
        return {
          uuid: factura.uuid,
          installment: arregloInstallmentPago[i].installment + 1,
          last_balance: factura.total - arregloInstallmentPago[i].totalPagos,
          amount: factura.aplicarPago
        };

      });

    const recepcionDePago = {
      customerId: this.facturasRelacionadas[0].customer.id,
      payment_form: this.formaDePagoValue,
      related: related,
    };

    this.aplicandoPago = true;
    this._facturasService.crearRecepcionDePago( recepcionDePago ).subscribe(
      (resp) => {
        this.aplicandoPago = false;
        this.facturasRelacionadas = [];
        this.formaDePago = null;
        this.pagoRecibido = 0;
        
        swal(
          'Recepción de pago exitosa',
          'La factura para recepción de pago se generó exitosamente',
          'success'
        );


    }, (error) => {
        this.aplicandoPago = false;
        swal(
          'Error al crear recepción de pago',
          error.error.mensaje + ' | ' + error.error.errors.message,
          'error'
        );
    });
  }


}
