import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../config/config';
import { UsuarioService } from './usuarios/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class ProductosBlumService {

  constructor(
    private _usuarioService: UsuarioService,
    private http: HttpClient
  ) { }

  buscarProductoBlumPorEAN( idProducto ) {
    const token = this._usuarioService.token;
    const url = URL_SERVICIOS + `/catalogoBlum/${ idProducto }?token=${ token }`;

    return this.http.get( url );
  }

}
